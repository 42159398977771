<template>
  <v-app-bar
    clipped-left
    elevation="0"
    color="#305947"
  >
    <v-toolbar-title>
      <span @click="goToHome" style="cursor: pointer;">
        <v-img src="@/assets/pictures/logo-new.jpg" height="50px" width="100%" contain position="left" class="mt-2"/>
      </span>
    </v-toolbar-title>

    <v-spacer />
    <v-toolbar-items>

    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
  export default {

    name: "TopbarAuth",

    components: {
    },

    data() {
      return {
      }
    },

    methods: {
      goToHome() {
        window.location = "https://admin.fairlymade.dev"
      }
    }
  }
</script>

<style scoped>

</style>